/* eslint-disable */
import React, { Component } from "react";
import axios from "axios";
import Header from "../Layout/Header";
import cookie from "react-cookies";
import { apiUrl, unquieID } from "../Settings/Config";
import reloadQr from "../../common/images/reload_qr.png";
import { showAlert } from "../Helpers/SettingHelper";
import Swal from "sweetalert2";
var qs = require("qs");
var downloadTimer = "";
class RedeemForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_page: "Redeem Credits",
      user_credits: "",
      user_credits_error: "",

      qrcode_str: "",
      runingNum: 0,
      qrCode: "",
      inititalLoad: true,
    };

    if (
      cookie.load("LoginUserId") === undefined ||
      cookie.load("LoginUserId") === ""
    ) {
      props.history.push("/");
    }
  }
  componentDidMount() {}

  handleFldChange(event) {
    const re = /^[0-9 \b]+$/;
    //if(event.target.value === "") {
    var mblnumber = this.space(event.target.value);
    var mblnumberLenght = mblnumber.length;
    this.setState({ user_credits: mblnumber });
    //}
    this.setState({ user_credits_error: "" });
  }

  handleFldChange_old(event) {
    const re = /^[0-9 \b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      var mblnumber = this.space(event.target.value);
      var mblnumberLenght = mblnumber.length;
      if (mblnumberLenght <= 9) {
        this.setState({ user_credits: mblnumber });
      }
    }
    this.setState({ user_credits_error: "" });
  }

  space(el) {
    var numbes = el.replace(/ /g, "");
    return numbes.replace(/(\d{4})/g, "$1 ").replace(/(^\s+|\s+$)/, "");
  }

  gotoRedeemFun(event) {
    event.preventDefault();
    let userCredits = this.state.user_credits;
    if (parseFloat(userCredits) > 0) {
      this.generateCustQrcode();
      /*  localStorage.setItem("usercredits", userCredits);
      this.props.history.push("/scanqrcode"); */
    } else {
      this.setState({ user_credits_error: "Please Enter Credits" });
    }
  }

  generateCustQrcode() {
    var postObject = {};
    postObject = {
      app_id: unquieID,
      qr_type: "points",
      redeemAmount: this.state.user_credits,
      user_id: cookie.load("LoginUserId"),
      outletID: cookie.load("LoginUserOutlet"),
    };
    axios
      .post(
        apiUrl + "customer/generateRedeemCashQrcode",
        qs.stringify(postObject)
      )
      .then((res) => {
        if (res.data.status === "ok") {
          let qrData =
            res.data.common.image_source +
            "/" +
            res.data.result_set.cust_qr_image;
          this.setState(
            {
              qrcodeData: res.data.result_set,
              qrcode_str: qrData,
              qrCode: res.data.result_set.cust_qr_code,
              runingNum: 20,
            },
            function () {
              this.setRuningNum();
              if (this.state.inititalLoad === true) {
                this.setState({ inititalLoad: false }, function () {
                  this.checkingQRstatus();
                });
              }
            }.bind(this)
          );
        } else {
          showAlert("Error", res.data.message, "error");
        }
      });
  }

  checkingQRstatus() {
    var postObject = {};
    postObject = {
      app_id: unquieID,
      qrcode: this.state.qrCode,
      user_id: cookie.load("LoginUserId"),
    };
    axios
      .post(apiUrl + "customer/checkRedeemCashQrcode", qs.stringify(postObject))
      .then((res) => {
        var currentThis = this;
        if (res.data.status === "ok") {
          if (this.state.runingNum > 0) {
            setTimeout(function () {
              currentThis.checkingQRstatus();
            }, 3000);
          }
        } else if (res.data.status === "used") {
          Swal.fire({
            title: "Success",
            html: res.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary waves-effect waves-light",
            },
            buttonsStyling: false,
          });
          this.props.history.push("/transactions");
        } else {
        }
      });
  }

  setRuningNum() {
    var rct_this = this;
    var runingNum = this.state.runingNum;
    downloadTimer = setInterval(function () {
      if (runingNum <= 0) {
        clearInterval(downloadTimer);
      }
      runingNum = runingNum - 1;
      rct_this.setState({ runingNum: runingNum }, function () {
        if (runingNum <= 0) {
          rct_this.props.history.push("/menu");
        }
      });
    }, 1000);
  }
  reloadQrFun(event) {
    event.preventDefault();
    var customerId = this.state.UserId;
    this.generateCustQrcode();
  }

  render() {
    let runingNum = this.state.runingNum;
    let qrcode_str = this.state.qrcode_str;
    return (
      <div className="main-div">
        <Header mainpagestate={this.state} prntPagePrps={this.props} />

        <div className="rel">
          <div className="container textcenter">
            <div className="callout">
              {qrcode_str !== "" && (
                <div className="mbtm-need-less rel">
                  <div className="voucher-redeem-detail textcenter">
                    <div className="vod-header">
                      <h2>Redeem Now</h2>
                      <p>Please show this QR code to customer</p>
                    </div>
                    <div className="vod-body">
                      {runingNum > 0 ? (
                        <img src={qrcode_str} />
                      ) : (
                        <img
                          src={reloadQr}
                          onClick={this.reloadQrFun.bind(this)}
                        />
                      )}
                    </div>
                    {runingNum > 0 && (
                      <div className="vod-footer">
                        <span>QR Code expire in</span>
                        <h2>{this.state.runingNum}</h2>
                        <p>Seconds</p>
                      </div>
                    )}
                    <br></br>
                  </div>
                </div>
              )}

              <p>
                Please enter credit amount
                <br /> to redeem here
              </p>
              <input
                type="input"
                inputMode="decimal"
                pattern="[0-9]*"
                placeholder="Enter credit amount here"
                value={this.state.user_credits}
                onChange={this.handleFldChange.bind(this)}
              />
              {this.state.user_credits_error != "" && (
                <p className="error_info">{this.state.user_credits_error}</p>
              )}
              <a
                href="#"
                className="button"
                onClick={this.gotoRedeemFun.bind(this)}
              >
                Continue to scan QR
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RedeemForm;
